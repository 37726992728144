* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Example_App__1lfMA {
  display: -webkit-flex;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #fff; }

.Example_Header__mfWzq {
  font-weight: 300; }

.Example_Header__mfWzq strong {
  font-weight: 600; }

.Example_Header__mfWzq small {
  font-size: 1rem; }

.Example_Wrapper__2Q8uu {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: auto;
          flex: auto;
  -webkit-flex-direction: row;
          flex-direction: row; }
  @media (max-width: 865px) {
    .Example_Wrapper__2Q8uu {
      -webkit-flex-direction: column;
              flex-direction: column; } }

.Themer_Themer__2O8om {
  font-size: 13px;
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  position: absolute;
  left: 1rem;
  top: 6rem;
  min-width: 252px; }
  .Themer_Themer__2O8om .Themer_header__OS_IM {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 24px;
    cursor: move;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: .3rem .7rem;
    font-weight: 300; }
  .Themer_Themer__2O8om .Themer_exportBtn__a3kH1 {
    margin-left: auto;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer; }
    .Themer_Themer__2O8om .Themer_exportBtn__a3kH1:hover {
      text-decoration: underline; }
  .Themer_Themer__2O8om .Themer_body__csjJL {
    padding: .5rem; }
  .Themer_Themer__2O8om .Themer_Check__557kw {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    cursor: pointer;
    margin-right: .5rem; }

.Themer_exporter__U6Tvj {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: inherit;
  background: rgba(40, 44, 52, 0.36); }
  .Themer_exporter__U6Tvj > div {
    min-width: 60vw;
    min-height: 70vh;
    max-height: 100%;
    overflow: hidden auto;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    background: #282c34;
    color: #c7d8fc; }
    .Themer_exporter__U6Tvj > div p {
      margin: 0;
      line-height: 130%; }
    .Themer_exporter__U6Tvj > div .Themer_header__OS_IM {
      height: 30px;
      display: -webkit-flex;
      display: flex; }
      .Themer_exporter__U6Tvj > div .Themer_header__OS_IM button {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        color: white;
        cursor: pointer;
        font-size: 1rem;
        background: transparent;
        width: 30px;
        height: 100%;
        border: none;
        outline: none;
        margin-left: auto; }
        .Themer_exporter__U6Tvj > div .Themer_header__OS_IM button:hover {
          background: rgba(0, 0, 0, 0.5); }
    .Themer_exporter__U6Tvj > div .Themer_body__csjJL {
      padding: 2rem; }
  .Themer_exporter__U6Tvj .Themer_tab__dO3p3 {
    padding-left: 2rem; }
  .Themer_exporter__U6Tvj .Themer_tab2__1ICDY {
    padding-left: 4rem; }
  .Themer_exporter__U6Tvj .Themer_tab3__2iwir {
    padding-left: 6rem; }

.Themer_ExportedString__3YiwC {
  font-size: inherit;
  font-weight: 600; }
  .Themer_ExportedString__3YiwC .Themer_violet__q3kxa {
    color: #c578dc; }
  .Themer_ExportedString__3YiwC .Themer_orange__3h_G7 {
    color: #c59966; }
  .Themer_ExportedString__3YiwC .Themer_yellow__9UTuM {
    color: #debf7b; }
  .Themer_ExportedString__3YiwC .Themer_blue__2WtWF {
    color: #61aeee; }
  .Themer_ExportedString__3YiwC .Themer_red__3Yz4o {
    color: #df6c75; }
  .Themer_ExportedString__3YiwC .Themer_green__3L6aM {
    color: #97c279; }

.Themer_colorPickerPreview__egw1c {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: .3rem;
  width: 100%;
  transition: height 2s; }
  .Themer_colorPickerPreview__egw1c .Themer_head__3buoi {
    padding: .5rem 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

.Themer_colorPreview__1d33Z {
  margin-left: auto;
  display: -webkit-flex;
  display: flex;
  padding: .2rem;
  background: rgba(0, 0, 0, 0.2); }
  .Themer_colorPreview__1d33Z > div {
    width: 20px;
    height: 20px; }

.Themer_Slider__2DeZr {
  position: relative;
  width: 100%;
  height: 8px;
  border: 1px solid steelblue; }

.Themer_Rail__1U9bd {
  position: absolute;
  width: 100%;
  height: 10px;
  margin-top: 35px;
  border-radius: 5px;
  background-color: #8B9CB6; }

input[type='checkbox'] {
  height: 0;
  width: 0;
  position: absolute; }

input[type='checkbox'] + label {
  position: relative;
  display: -webkit-flex;
  display: flex;
  margin: 0;
  -webkit-align-items: center;
          align-items: center;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1); }

input[type='checkbox'] + label > span {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-right: .5rem;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1); }

input[type='checkbox'] + label:hover,
input[type='checkbox']:focus + label {
  color: #fff; }

input[type='checkbox'] + label:hover > span,
input[type='checkbox']:focus + label > span {
  background: rgba(255, 255, 255, 0.2); }

input[type='checkbox']:checked + label > span {
  border: 10px solid rgba(18, 18, 25, 0.76);
  -webkit-animation: Themer_shrink-bounce__XG4ra 200ms cubic-bezier(0.4, 0, 0.23, 1);
          animation: Themer_shrink-bounce__XG4ra 200ms cubic-bezier(0.4, 0, 0.23, 1); }

input[type='checkbox']:checked + label > span:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 4px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  -webkit-animation: Themer_checkbox-check__ksnxr 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
          animation: Themer_checkbox-check__ksnxr 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards; }

@-webkit-keyframes Themer_shrink-bounce__XG4ra {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  33% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes Themer_shrink-bounce__XG4ra {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  33% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes Themer_checkbox-check__ksnxr {
  0% {
    width: 0;
    height: 0;
    border-color: #ffffff;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  33% {
    width: .2em;
    height: 0;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  100% {
    width: .2em;
    height: .5em;
    border-color: #ffffff;
    -webkit-transform: translate3d(0, -0.5em, 0) rotate(45deg);
            transform: translate3d(0, -0.5em, 0) rotate(45deg); } }

@keyframes Themer_checkbox-check__ksnxr {
  0% {
    width: 0;
    height: 0;
    border-color: #ffffff;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  33% {
    width: .2em;
    height: 0;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  100% {
    width: .2em;
    height: .5em;
    border-color: #ffffff;
    -webkit-transform: translate3d(0, -0.5em, 0) rotate(45deg);
            transform: translate3d(0, -0.5em, 0) rotate(45deg); } }

.ShowCase_ShowCase__2WDU- {
  font-size: 13px;
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  position: absolute;
  right: 2rem;
  top: 6rem;
  min-width: 252px; }
  .ShowCase_ShowCase__2WDU- .ShowCase_header__Af7iR {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 24px;
    cursor: move;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: .3rem .7rem;
    font-weight: 300; }
  .ShowCase_ShowCase__2WDU- .ShowCase_exportBtn__1oGi2 {
    margin-left: auto;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer; }
    .ShowCase_ShowCase__2WDU- .ShowCase_exportBtn__1oGi2:hover {
      text-decoration: underline; }
  .ShowCase_ShowCase__2WDU- .ShowCase_body__3aPyi {
    padding: .5rem; }
  .ShowCase_ShowCase__2WDU- .ShowCase_Check__30M4y {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%;
    cursor: pointer;
    margin-right: .5rem; }

.ShowCase_exporter__2WWJw {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: inherit;
  background: rgba(40, 44, 52, 0.36); }
  .ShowCase_exporter__2WWJw > div {
    min-width: 60vw;
    min-height: 70vh;
    max-height: 100%;
    overflow: hidden auto;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    background: #282c34;
    color: #c7d8fc; }
    .ShowCase_exporter__2WWJw > div p {
      margin: 0;
      line-height: 130%; }
    .ShowCase_exporter__2WWJw > div .ShowCase_header__Af7iR {
      height: 30px;
      display: -webkit-flex;
      display: flex; }
      .ShowCase_exporter__2WWJw > div .ShowCase_header__Af7iR button {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center;
        color: white;
        cursor: pointer;
        font-size: 1rem;
        background: transparent;
        width: 30px;
        height: 100%;
        border: none;
        outline: none;
        margin-left: auto; }
        .ShowCase_exporter__2WWJw > div .ShowCase_header__Af7iR button:hover {
          background: rgba(0, 0, 0, 0.5); }
    .ShowCase_exporter__2WWJw > div .ShowCase_body__3aPyi {
      padding: 2rem; }
  .ShowCase_exporter__2WWJw .ShowCase_tab__36ds0 {
    padding-left: 2rem; }
  .ShowCase_exporter__2WWJw .ShowCase_tab2__3DATE {
    padding-left: 4rem; }
  .ShowCase_exporter__2WWJw .ShowCase_tab3__2TNAE {
    padding-left: 6rem; }

.ShowCase_ExportedString__29ou8 {
  font-size: inherit;
  font-weight: 600; }
  .ShowCase_ExportedString__29ou8 .ShowCase_violet__O7r0U {
    color: #c578dc; }
  .ShowCase_ExportedString__29ou8 .ShowCase_orange__2Ewei {
    color: #c59966; }
  .ShowCase_ExportedString__29ou8 .ShowCase_yellow__EAwxi {
    color: #debf7b; }
  .ShowCase_ExportedString__29ou8 .ShowCase_blue__1Nb5- {
    color: #61aeee; }
  .ShowCase_ExportedString__29ou8 .ShowCase_red__3v3Qb {
    color: #df6c75; }
  .ShowCase_ExportedString__29ou8 .ShowCase_green__3jPpp {
    color: #97c279; }

.ShowCase_Item__3RFyL {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: .3rem;
  width: 100%;
  transition: height 2s;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center; }
  .ShowCase_Item__3RFyL .ShowCase_head__1W9Fn {
    padding: .5rem 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

.ShowCase_Slider__1fVj1 {
  position: relative;
  width: 100%;
  height: 8px;
  border: 1px solid steelblue; }

.ShowCase_Rail__Q4MY2 {
  position: absolute;
  width: 100%;
  height: 10px;
  margin-top: 35px;
  border-radius: 5px;
  background-color: #8B9CB6; }

input[type='checkbox'] {
  height: 0;
  width: 0;
  position: absolute; }

input[type='checkbox'] + label {
  position: relative;
  display: -webkit-flex;
  display: flex;
  margin: 0;
  -webkit-align-items: center;
          align-items: center;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: color 250ms cubic-bezier(0.4, 0, 0.23, 1); }

input[type='checkbox'] + label > span {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-right: .5rem;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1); }

input[type='checkbox'] + label:hover,
input[type='checkbox']:focus + label {
  color: #fff; }

input[type='checkbox'] + label:hover > span,
input[type='checkbox']:focus + label > span {
  background: rgba(255, 255, 255, 0.2); }

input[type='checkbox']:checked + label > span {
  border: 10px solid rgba(18, 18, 25, 0.76);
  -webkit-animation: ShowCase_shrink-bounce__2mr-b 200ms cubic-bezier(0.4, 0, 0.23, 1);
          animation: ShowCase_shrink-bounce__2mr-b 200ms cubic-bezier(0.4, 0, 0.23, 1); }

input[type='checkbox']:checked + label > span:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 4px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
  -webkit-animation: ShowCase_checkbox-check__1BNvu 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
          animation: ShowCase_checkbox-check__1BNvu 125ms 250ms cubic-bezier(0.4, 0, 0.23, 1) forwards; }

@-webkit-keyframes ShowCase_shrink-bounce__2mr-b {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  33% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes ShowCase_shrink-bounce__2mr-b {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  33% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes ShowCase_checkbox-check__1BNvu {
  0% {
    width: 0;
    height: 0;
    border-color: #ffffff;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  33% {
    width: .2em;
    height: 0;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  100% {
    width: .2em;
    height: .5em;
    border-color: #ffffff;
    -webkit-transform: translate3d(0, -0.5em, 0) rotate(45deg);
            transform: translate3d(0, -0.5em, 0) rotate(45deg); } }

@keyframes ShowCase_checkbox-check__1BNvu {
  0% {
    width: 0;
    height: 0;
    border-color: #ffffff;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  33% {
    width: .2em;
    height: 0;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  100% {
    width: .2em;
    height: .5em;
    border-color: #ffffff;
    -webkit-transform: translate3d(0, -0.5em, 0) rotate(45deg);
            transform: translate3d(0, -0.5em, 0) rotate(45deg); } }

