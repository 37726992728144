.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #fff;
}

.Header {
  font-weight: 300;
}

.Header strong {
  font-weight: 600;
}

.Header small {
  font-size: 1rem;
}

.Wrapper {
  height: 100%;
  display: flex;
  flex: auto;
  flex-direction: row;
  
  @media (max-width: 865px) {
    flex-direction: column;
  }
}