.ShowCase {
  font-size: 13px;
  background: rgba(0, 0, 0, 0.4);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  position: absolute;
  
  right: 2rem;
  top: 6rem;
  min-width: 252px;
  
  .header {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 24px;
    cursor: move;
    display: flex;
    align-items: center;
    padding: .3rem .7rem;
    font-weight: 300;
  }
  
  .exportBtn {
    margin-left: auto;
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  .body {
    padding: .5rem;
  }
  
  .Check {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    margin-right: .5rem;
  }
}

.exporter {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  background: rgba(40, 44, 52, 0.36);
  
  
  > div {
    min-width: 60vw;
    min-height: 70vh;
    max-height: 100%;
    overflow: hidden auto;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    background: #282c34;
    color: #c7d8fc;
    
    p {
      margin: 0;
      line-height: 130%;
    }
  
    .header {
      height: 30px;
      display: flex;
      
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        cursor: pointer;
        font-size: 1rem;
        background: transparent;
        width: 30px;
        height: 100%;
        border: none;
        outline: none;
        margin-left: auto;
        
        &:hover {
          background: rgba(0,0,0,.5);
        }
      }
    }
    .body {
      padding: 2rem;
    }
  }
  
  .tab {
    padding-left: 2rem;
  }
  .tab2 {
    padding-left: 4rem;
  }
  .tab3 {
    padding-left: 6rem;
  }
}

.ExportedString {
  font-size: inherit;
  font-weight: 600;
  
  .violet {
    color: #c578dc;
  }
  .orange {
    color: #c59966;
  }
  .yellow {
    color: #debf7b;
  }
  .blue {
    color: #61aeee;
  }
  .red {
    color: #df6c75;
  }
  .green {
    color: #97c279;
  }
}

.Item {
  display: flex;
  flex-direction: column;
  padding: .3rem;
  width: 100%;
  transition: height 2s;
  
  align-items: flex-start;
 justify-content: center;
  
  .head {
    padding: .5rem 0;
    display: flex;
    align-items: center;
  }
}

.Slider {
  position: relative;
  width: 100%;
  height: 8px;
  border: 1px solid steelblue,
}

.Rail {
  position: absolute;
  width: 100%;
  height: 10px;
  margin-top: 35px;
  border-radius: 5px;
  background-color: #8B9CB6;
}


// -------------------------------------------------------------
// -------------------------------------------------------------
input[type='checkbox'] {
  height: 0;
  width: 0;
  position: absolute
}

input[type='checkbox'] + label {
  position: relative;
  display: flex;
  margin: 0;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: color 250ms cubic-bezier(.4, .0, .23, 1);
}

input[type='checkbox'] + label > span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .5rem;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 2px solid rgba(0, 0, 0, .2);
  border-radius: 2px;
  cursor: pointer;
  transition: all 250ms cubic-bezier(.4, .0, .23, 1);
}

input[type='checkbox'] + label:hover,
input[type='checkbox']:focus + label {
  color: #fff;
}

input[type='checkbox'] + label:hover > span,
input[type='checkbox']:focus + label > span {
  background: rgba(255, 255, 255, .2);
}

input[type='checkbox']:checked + label > span {
  border: 10px solid rgba(18, 18, 25, 0.76);
  animation: shrink-bounce 200ms cubic-bezier(.4, .0, .23, 1);
}

input[type='checkbox']:checked + label > span:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 4px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checkbox-check 125ms 250ms cubic-bezier(.4, .0, .23, 1) forwards;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(.85);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #ffffff;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: .2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: .2em;
    height: .5em;
    border-color: #ffffff;
    transform: translate3d(0, -.5em, 0) rotate(45deg);
  }
}